import $api from './api'
import { TIMEOUT, ERR_OK, baseURL, STATUS, local } from './config'

/**
 * 商户区域分布情况
 */
export const regionalDistributionInfo = (params) => {
    return $api.get({
        url: baseURL + '/api/bus/regionalDistributionInfo',
        params
    })
}

/**
 * 四码统计
 */
export const fourCodeStatistics = (params) => {
    return $api.get({
        url: baseURL + '/api/bus/fourCodeStatistics',
        params
    })
}

/**
 * 预付码新增趋势
 */
export const safeCodeAdd = (params) => {
    return $api.post({
        url: baseURL + '/api/bus/safeCodeAdd',
        params
    })
}
/**
 * 预付码新增趋势
 */
export const safeCodeCumulative = (params) => {
    return $api.post({
        url: baseURL + '/api/bus/safeCodeCumulative',
        params
    })
}
/**
 * 预付码按城市/地区统计
 */
export const safeCodeCityStatistics = (params) => {
    return $api.post({
        url: baseURL + '/api/bus/safeCodeCityStatistics',
        params
    })
}
/**
 * 预付码按领域/行业统计
 */
export const safeCodeIndustryStatistics = (params) => {
    return $api.post({
        url: baseURL + '/api/bus/safeCodeIndustryStatistics',
        params
    })
}

// 各种主体状态数量统计
export const companyStatusStatistics = (params) => {
    return $api.get({
        url: baseURL + '/api/companyStatus/companyStatusStatistics',
        params
    })
}

// 主体状态区域分布统计
export const regionalCompanyStatus = (params) => {
    return $api.get({
        url: baseURL + '/api/companyStatus/regionalCompanyStatus',
        params
    })
}

// 主体状态新增趋势统计
export const companyStatusAdd = (params) => {
    return $api.post({
        url: baseURL + '/api/companyStatus/companyStatusAdd',
        params
    })
}

// 主体状态累计趋势统计
export const companyStatusCumulative = (params) => {
    return $api.post({
        url: baseURL + '/api/companyStatus/companyStatusCumulative',
        params
    })
}

// 主体状态按地区统计
export const companyStatusCityStatistics = (params) => {
    return $api.post({
        url: baseURL + '/api/companyStatus/companyStatusCityStatistics',
        params
    })
}

// 主体状态按领域统计
export const companyStatusIndustryStatistics = (params) => {
    return $api.post({
        url: baseURL + '/api/companyStatus/companyStatusIndustryStatistics',
        params
    })
}